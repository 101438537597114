import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import Section from '../layout/Section';
import { colors } from '../../includes/style';
import { media } from '../../includes/breakpoints';
import PlainImage from '../utils/PlainImage';
import { graphql, useStaticQuery } from 'gatsby';
import ArrowLeft from '../../images/icons/arrow-left.inline.svg';
import ArrowRight from '../../images/icons/arrow-right.inline.svg';

const StyledSection = styled(Section)`
  background-color: ${colors.silver};
  text-align: center;

  .cards {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .subheadline {
    margin-bottom: 40px;
  }
`;

const cardWidth = 320;

const Cards = styled.div`
  .card {
    background-color: #fff;
    padding: 30px 15px;
    border-radius: 16px;
    margin-bottom: 35px;
    transition: color, background-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    border: 0;
    cursor: auto;
    user-select: text;

    ${media.desktop`
      max-width: ${cardWidth}px;
      border: 2px solid ${colors.oceanBlue};
      padding: 24px;
      cursor: pointer;
      margin-bottom: 0;
      border-radius: 16px;

      &:not(button:last-of-type) {
        margin-bottom: 24px;
      }

      &:hover,
      &:active,
      &:focus,
      &.active {
        background-color: ${colors.oceanBlue};
        color: #fff;
      }

      &.active {
        .card__img-wrapper {
          opacity: 1;
        }
      }
    `}
  }

  .card__img-wrapper {
    margin-bottom: 22px;
    border-radius: 16px;
    pointer-events: none;
    position: relative;

    &:after {
      content: attr(data-caption);
      color: #fff;
      font-size: 12px;
      position: absolute;
      bottom: 24px;
      left: 24px;
      text-align: left;
      padding-right: 24px;
    }

    ${media.desktop`
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: calc(100% - (${cardWidth}px + 24px));
      height: 100%;
      border-radius: 24px;
    `}

    .card__img {
      width: 100%;
      height: 100%;
      border-radius: 16px;
      object-fit: cover;

      ${media.desktop`
        border-radius: 24px;
      `}
    }
  }

  .card__text {
    padding: 0 24px;
    ${media.desktop`
      padding: 0;
    `}
  }

  .card__title {
    font-weight: 600;
    text-align: left;
    line-height: 19px;
  }

  .card__copy {
    text-align: left;
    line-height: 24px;
  }
`;

const Arrows = styled.div`
  display: none;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  top: 50%;
  bottom: 50%;
  right: 0;
  width: calc(100% - (${cardWidth}px + 24px));
  height: auto;
  ${media.desktop`
    display: flex;
  `}

  .prev,
  .next{
    width: 30px;
    height: 30px;
    background-color: rgba(0,0,0, 0.3);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    border: 0;

    &:hover,
    &:active,
    &:focus {
      background-color: #000;
    }
    svg path {
      fill: #fff;
    }
  }

  .prev {
    margin-left: 20px;
  }

  .next {
    margin-right: 20px;
  }
`;

const Bubbles = styled.div`
  display: none;
  position: absolute;
  justify-content: center;
  align-items: center;
  bottom: 24px;
  right: 24px;

  ${media.desktop`
    display: flex;
  `}

  .bubble {
    transition: color, background-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: rgba(255,255,255, 0.3);
    cursor: pointer;
    border: 0;
    margin-left: 10px;

    &:hover,
    &:active,
    &:focus,
    &.active {
      background-color: #fff;
    }
  }
`;

type Case = {
  title: ReactNode,
  copy: ReactNode,
  image: any,
  caption: string,
};
export const CompanyCase:Case = {
  title: `Company updates and launches`,
  copy: `Present a new product live and demonstrate how it works to your community.`,
  image: ``,
  caption: `Mercedes-Benz used Restream to live stream the EQS World Premiere`,
};

export const WebinarCase:Case = {
  title: `Webinars, classes and workshops`,
  copy: `Share your knowledge with the world by going live with webinars, classes and workshops.`,
  image: ``,
  caption: `Adobe using Restream to broadcast live webinars with their creators`,
};

export const ChurchCase:Case = {
  title: `Church service`,
  copy: `Let your message reach more people, no matter where they are, with no additional work.`,
  image: ``,
  caption: `Elevation Church using Restream to premiere their service live`
};


export default function Use(){
  const data = useStaticQuery(graphql`
    query ScreenshotImagesQuery {
      screenshot1: file(relativePath: {eq: "use/mercedes.jpg"}){
        ...screenshot
      }
      screenshot2: file(relativePath: {eq: "use/workshop.jpg"}){
        ...screenshot
      }
      screenshot3: file(relativePath: {eq: "use/church.jpg"}){
        ...screenshot
      }
    }
    fragment screenshot on File {
      img: childImageSharp {
        gatsbyImageData(layout: FIXED, quality: 90)
        fixed {
          src, srcWebp
        }
      }
    }
  `);

  const cards = [CompanyCase, WebinarCase, ChurchCase];
  const [ activeIndex, setActiveIndex ] = useState(0);

  Object.values(data).map((value, index)=>{
    cards[index].image = value;
  });

  type CardProps = {
    key: number|string,
    index: number,
    children: ReactNode
  };
  const Card = ({ index, children }:CardProps)=>{
    return <button
      className={`card${activeIndex === index ? ' active' : ''}`}
      data-index={index}
      key={index}
      type="button"
      role="group"
      aria-label={`slide ${index + 1} / ${cards.length}`}
      aria-roledescription="slide"
      onClick={()=>setActiveIndex(index)}
    >
      { children }
    </button>;
  };

  type BubbleProps = {
    key: number|string,
    index: number
  };
  const Bubble = ({ index }:BubbleProps)=>{
    return <button
      className={`bubble${activeIndex === index ? ' active' : ''}`}
      data-index={index}
      key={index}
      type="button"
      onClick={()=>setActiveIndex(index)}
    >
      <span className='sr-only'>{`Go to slide ${index + 1}`}</span>
    </button>;
  };

  type ArrowProps = {
    slide: string,
    children: ReactNode
  };
  const Arrow = ({ slide, children }:ArrowProps)=>{
    return <button
      className={slide}
      data-slide={slide}
      type="button"
      onClick={e=>{
        if (slide === 'prev') {
          const prevIndex = activeIndex <= 0 ? cards.length - 1 : activeIndex - 1;
          setActiveIndex(prevIndex);
        }
        else if (slide === 'next') {
          const nextIndex = activeIndex >= cards.length - 1 ? 0 : activeIndex + 1 ;
          setActiveIndex(nextIndex);
        }
      }}
    >
      { children }
    </button>;
  };

  return <StyledSection id="use">
    <h2>Use cases</h2>
    <p className="subheadline">Whatever you stream, be among the best in the industry</p>
    <Cards className="cards" data-index={activeIndex} aria-live="polite">
      {cards.map((card, index)=>{
        return <Card index={index} key={index}>
          <div className="card__img-wrapper" data-caption={card.caption}>
            <PlainImage className="card__img" imgData={card.image} defer={true} alt={card.caption} />
          </div>
          <div className="card__text">
            <p className='card__title'>{card.title}</p>
            <p className='card__copy'>{card.copy}</p>
          </div>
        </Card>;
      })}
      <Arrows className="cards__arrows-control">
        <Arrow slide='prev'>
          <span aria-hidden="true"><ArrowLeft /></span>
          <span className="sr-only">Previous Slide</span>
        </Arrow>
        <Arrow slide='next'>
          <span aria-hidden="true"><ArrowRight /></span>
          <span className="sr-only">Next Slide</span>
        </Arrow>
      </Arrows>
      <Bubbles className="cards__bubbles-control">
        {cards.map((card, index)=>{
          return <Bubble index={index} key={index}></Bubble>;
        })}
      </Bubbles>
    </Cards>
  </StyledSection>;
}
