import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Hero from '../../components/home/Hero';
import Plans from '../../components/home/Plans';
import Quote from '../../components/home/Quote';
import Audience from '../../components/home/Audience';
import How, { ConnectSlide, EngageSlide, GoLiveSlide } from '../../components/home/How';
import Use from '../../components/home/Use';
import ExitIntentModal from '../../components/modals/ExitIntentModal';
import Footer from '../../components/layout/Footer';

type FacebookProps = {
  data: FacebookQueryData
};
export default function Facebook({ data }:FacebookProps) {
  return <>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <meta name="robots" content="noindex,nofollow" />
      <meta name="description" content="Restream is your live streaming companion. Easily go live on Facebook, YouTube, and many other platforms at the same time." />
      <title>Multistream to your favorite social channels | {data.site.siteMetadata.name}</title>
    </Helmet>
    <Hero
      headline={<>Facebook live<br />video streaming<br /> made simple</>}
      subHeadline={<>Create beautiful videos and stream them to your favorite channels (like Facebook, YouTube and Twitch) at the same time.</>}
      btnText ={<>Go Live Today</>}
    />
    <How
      initialSlide={0}
      slides={[
        {
          ...ConnectSlide,
          copy: `Choose from 30+ channels including Facebook, YouTube, Twitch and LinkedIn`,
        },
        {
          ...GoLiveSlide,
          copy: `With Restream Studio, Zoom or your favorite streaming software`,
        },
        {
          ...EngageSlide,
          copy: `Connect and chat with your audience, all from one place`,
        },
      ]}
    />
    <Quote />
    <Plans />
    <Use />
    <Audience headline={<>Your Facebook audience awaits</>}/>
    <ExitIntentModal />
    <Footer />
  </>;
}

type FacebookQueryData = {
  site: {
    siteMetadata: {
      name: string,
      description: string,
    }
  }
};
export const query = graphql`
  query {
    site {
      siteMetadata {
        name
        description
      }
    }
  }
`;
