import React, { useState } from 'react';
import ExitIntent from '../utils/ExitIntent';
import Modal from './Modal';
import Logo from '../../images/logo.inline.svg';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { media } from '../../includes/breakpoints';
import UtmCta from '../utils/UtmCta';

// const delay = 15000;
const delay = 0;

const StyledModal = styled(Modal)`
  display: none;

  ${media.desktop`
    display: flex;
  `}

  .modal-inner{
    background: rgba(238,238,238,1);
    box-sizing: border-box;
    min-width: 736px;
    height: 376px;
    overflow: hidden;
  }

  .modal-content{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .modal-close-icon{
    border-radius: 50%;
    border: none;
    background-color: hsla(0,0%,100%,.6);
    color: #525151;
    display: flex;
    height: 1em;
    width: 1em;
    font-size: 20px;
    line-height: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    transition: transform .2s ease-in-out,background-color .2s ease-in-out;
    position: absolute;
    right: 10px;
    top: 10px;

    &:hover{
      background-color: white;
      transform: scale(1.2);
    }
  }

  .row{
    display: flex;

    .col{
      width: 50%;
    }
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 60px;

  .text{
    position: absolute;
    top: 60px;
    left: 50px;
    font-size: 44px;
    font-weight: bold;
  }
`;

const Row = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
`;

const ModalCopy = styled.div`
  text-align: center;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .heading{
    font-size: 24px;
    font-weight: normal;
  }

  .code{
    font-size: 20px;
  }

  .btn--small{
    font-size: 12px;
    border: none;
  }

  .btn--transparent{
    background: transparent;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;

  .buttons{
    display: inline-grid;
    grid-template-columns: 1fr;
  }
`;

export default function ExitIntentModal(){
  const [ isOpen, setState ] = useState(false);

  const open = ()=>{ setState(true); };
  const close = ()=>{ setState(false); };

  return <ExitIntent delay={delay} onActivate={open}>
    <StyledModal id="exit-modal" isOpen={isOpen} onClose={close}>
      <Logo />
      <ImageWrapper className="image-wrapper">
        <StaticImage src="../../images/exit-intent/talk-bubble.png" alt="" width={215} height={300} layout="constrained" loading="eager" placeholder="none" />
        <div className="text">WAIT!</div>
      </ImageWrapper>
      <Row>
        <ModalCopy className="modal-copy">
          <div className="heading">Subscribe now to <strong>get 20% off</strong> your Restream plan.</div>
          <div className="code">Use Code: <em>GOLIVE2022</em></div>
          <ButtonWrapper>
            <div className="buttons">
              <UtmCta className="btn btn--blue" href="https://restream.io/signup?continue=%2Fplans%2Fupgrade%3FpromoCode%3DGOLIVE2022-UN%26from=cpc-sem" target="_blank" rel="noreferrer">Get Discount</UtmCta>
              <button className="btn btn--small btn--transparent" type="button" onClick={close}>No thanks, I don't need 20% OFF</button>
            </div>
          </ButtonWrapper>
        </ModalCopy>
      </Row>
    </StyledModal>
  </ExitIntent>;
}
