
import React, { ReactNode, useEffect, useState } from 'react';
import { isMobile } from '../../includes/analytics';

/**
 * @description Runs a callback after the "DOM ready" event, or immediately if it has already happened.
 */
export function domReady(callback){
  if (document.readyState === 'interactive' || document.readyState === 'complete') callback();
  else document.addEventListener('DOMContentLoaded', callback);
}

type ExitIntentProps = {
  scrollPointInView?:boolean,
  lastScrollTop?:number,
  scrollPoint?:number,
  delay?:number,
  onActivate?:()=>void,
  children?:ReactNode,
};
export default function ExitIntent({
  scrollPointInView = false,
  lastScrollTop = 0,
  scrollPoint = 100,
  delay = 0,
  onActivate,
  children,
}:ExitIntentProps){
  const [ active, setActive ] = useState(false);

  useEffect(()=>{
    if (active) {
      console.debug(`Exit intent has already previously activated.`);
    }else{
      if (delay) console.debug(`Waiting ${delay}ms before enabling Exit Intent.`);
      setTimeout(()=>{
        domReady(() => {
          if (isMobile) return attachMobileEvents();
          else return attachDesktopEvents();
        });
      }, delay);
    }
  }, []);

  const triggerIntent = ()=>{
    if (active) return;
    setActive(true);
    onActivate?.();
    console.debug('Exit Intent triggered.');
  };

  const attachMobileEvents = ()=>{
    console.debug(`Attaching mobile exit intent listener.`);
    const eventHandler = () => {
      const scrollTop =
        window.pageYOffset ||
        (document.documentElement.clientHeight ? document.documentElement.scrollTop : document.body.scrollTop);

      // watch for the scroll point to be above the bottom of the window
      if (!scrollPointInView) {
        const scrollBottom = scrollTop + (document.documentElement.clientHeight || window.innerHeight);
        console.debug(`Scroll detected, scroll top/bottom are ${scrollTop} / ${scrollBottom}. Watching for scroll point...`);

        if (scrollPoint <= scrollBottom) {
          scrollPointInView = true;
          console.debug('Scroll point is in view');
        }
      } else {
        // user has scrolled down far enough
        // watch for them to scroll back up instead
        console.debug(`Scroll detected, scroll top is ${scrollTop}. Waiting for scroll up...`);
        if (scrollTop < lastScrollTop - 10) {
          triggerIntent(); // fire the callback!
          document.removeEventListener('scroll', eventHandler);
        }
        lastScrollTop = scrollTop;
      }
    };
    document.addEventListener('scroll', eventHandler);
  };

  const attachDesktopEvents = ()=>{
    console.debug(`Attaching desktop exit intent listener.`);
    const eventHandler = (e) => {
      const x = e.clientX;
      const y = e.clientY;
      // make sure they actually moved outside the window (i.e. not mousing over autocomplete)
      if (x < 0 || x > window.innerWidth || y < 0 || y > window.innerHeight || document.activeElement && !['BODY', 'INPUT'].includes(document.activeElement.tagName)) {
        // Above triggers if user mouses out via left/right/bottom
        triggerIntent();
        document.documentElement.removeEventListener('mouseleave', eventHandler);
      }
    };
    document.documentElement.addEventListener('mouseleave', eventHandler);
  };

  if (children){
    return <aside data-active={active}>{children}</aside>;
  }else{
    return <></>;
  }
}
