import React, { useEffect, useRef } from 'react';

type PlainImageProps = {
  imgData: any,
  defer?:boolean,
  alt?: string,
  className?: string,
  loading?: 'lazy' | 'eager' | undefined
  fallback?: boolean
};
export default function PlainImage({ imgData, defer = false, alt = '', className, loading = 'lazy', fallback = false}: PlainImageProps) {
  const imgProps:React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> = {
    className: className,
    alt: alt,
    height: imgData.img.gatsbyImageData.height,
    width: imgData.img.gatsbyImageData.width,
    loading: loading
  };

  const src = imgData.img.gatsbyImageData.images.fallback.src;
  if (defer){
    const imgRef = useRef<HTMLImageElement|null>(null);
    imgProps.ref = imgRef,
    useEffect(()=>{
      const img = imgRef.current as HTMLImageElement;
      img.src = src;
    }, []);
  }else{
    imgProps.src = src;
  }
  return <picture>
    {/* Fallback to smaller image sizes. Really craps on quality use with caution */}
    {fallback && <source srcSet={imgData.img.fixed.srcWebp} type="image/webp" />}
    {fallback && <source srcSet={imgData.img.fixed.src} type="image/jpeg" />}
    <img {...imgProps}></img>
  </picture>;
}
